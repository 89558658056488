import styled from '@emotion/styled';
import { colors, ImageIcon, CustomCard, Text, VideoIcon } from '@innovationdepartment/proxima-ui';
import { PerformanceAdsViewProps } from 'components/PerformanceAds/types';
import PerformanceAdsTilesResultsBar from './PerformanceAdsTiles.ResultsBar';
import InfiniteScroll from 'ui/InfiniteScroll';
import React from 'react';
import { CreativeType } from '../types';

const TilesWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  height: 100%;
  overflow: auto;
`;

const TilesContentWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
  grid-gap: 16px;
  overflow: auto;
  padding: 0 16px 16px;

  & > * {
    justify-self: center;
  }
`;

const TitleWrapper = styled.div`
  height: 44px;
  overflow: hidden;
  width: 100%;
  textoverflow: ellipsis;
`;

const creativeTypeTag = {
  [CreativeType.PHOTO]: { label: 'Photo', icon: <ImageIcon /> },
  [CreativeType.VIDEO]: { label: 'Video', icon: <VideoIcon /> },
  [CreativeType.CAROUSEL]: { label: 'Carousel', icon: <ImageIcon /> },
};

const FB_PERMALINK_URL = 'https://www.facebook.com/plugins/video.php?href=https://www.facebook.com';

type PerformanceAd = PerformanceAdsViewProps['ads']['data'][number];
const getCreativeAsset = (ad: PerformanceAd) => {
  const { creativeType, creativeAssets } = ad;
  const asset =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc_8xuBVttgoIei52J4NiD6ve1Q0nB588Fvg&s';

  const foundCreativeAsset = creativeAssets?.find(
    (creativeAsset) => creativeAsset.permalinkType === creativeType,
  )?.permalinkUrl;
  const thumbnail = creativeAssets?.find(
    (creativeAsset) => creativeAsset.permalinkType === 'PHOTO',
  )?.permalinkUrl;

  if (!foundCreativeAsset) {
    return {
      type: creativeType,
      asset,
      thumbnail: thumbnail ?? asset,
    };
  }

  if (creativeType === CreativeType.VIDEO) {
    return {
      type: creativeType,
      asset: foundCreativeAsset ? `${FB_PERMALINK_URL}${foundCreativeAsset}` : asset,
      thumbnail: thumbnail || asset,
    };
  }

  return {
    type: foundCreativeAsset,
    asset: thumbnail || asset,
    thumbnail: thumbnail || asset,
  };
};

const PerformanceAdsTable = (props: PerformanceAdsViewProps) => {
  const { ads, onRequestNext } = props;

  /* only show those that have assets/creativeType */
  const availableAds = ads.data.filter((ad) => !!ad.creativeType);

  return (
    <TilesWrapper>
      <PerformanceAdsTilesResultsBar {...props} />
      <TilesContentWrapper>
        {availableAds.map((ad, index) => {
          const { type, asset, thumbnail } = getCreativeAsset(ad);

          const openToNewTab = () => {
            window.open(
              asset,
              'targetWindow',
              `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=700,width=400`,
            );
          };

          return (
            <React.Fragment key={ad.adId}>
              <CustomCard
                key={ad.adId}
                size="tile"
                onClick={type === CreativeType.VIDEO ? openToNewTab : undefined}
                contentText={
                  <TitleWrapper>
                    <Text variant="body3">{ad.name}</Text>
                  </TitleWrapper>
                }
                label={<Text variant="body1Semibold">{ad.brand?.name}</Text>}
                headerProps={{
                  heroUrl: thumbnail,
                  tag: creativeTypeTag[ad.creativeType!],
                }}
              />
              {index === availableAds.length - 1 && onRequestNext && (
                <InfiniteScroll onInfiniteScroll={onRequestNext} />
              )}
            </React.Fragment>
          );
        })}
      </TilesContentWrapper>
    </TilesWrapper>
  );
};

export default PerformanceAdsTable;
